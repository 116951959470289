<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="$t('tCarouselDetail')"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('carouselManagement.order')" prop="order">
          <div>{{ ruleForm.order }}</div>
        </el-form-item>
        <el-form-item :label="$t('carouselManagement.link')" prop="link">
          <div>{{ ruleForm.link }}</div>
        </el-form-item>
        <el-form-item :label="$t('carouselManagement.image')" prop="image"
          >
          <el-image
            style="width: 100px; height: 100px"
            :src="ruleForm.image"
            :preview-src-list="[ruleForm.image]"
          >
          </el-image>
        </el-form-item>
        <el-form-item :label="$t('carouselManagement.h5_image')" prop="h5_image"
          >
          <el-image
            style="width: 100px; height: 100px"
            :src="ruleForm.h5_image"
            :preview-src-list="[ruleForm.h5_image]"
          >
          </el-image>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { carouselDetail } from "@/api/index";
export default {
  name: "carouselDetail",
  data() {
    return {
      ruleForm: {
        title: "",
        order: "",
        link: "",
        image: "",
        h5_image:""
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    }
  },
  methods: {
    async get_info() {
      const information = await carouselDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
